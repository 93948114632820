import React from 'react'
import { ValueRow, ValueCard } from './webreport/valueRow'

import * as style from './IntegrationValues.module.css'

const Page = (props) => {
  return (
    <div className={style.integrationValue}>
      <ValueRow>
        <ValueCard
          title="Import"
          description="Pomożemy Ci z importem danych historycznych"
        />
        <ValueCard
          title="Organizacja"
          description="Doradzimy jak wystawiać faktury, żeby analityka na ich podstawie była pewna"
        />
        <ValueCard
          title="Dane"
          description="Wszystkie dane spływaja na bieżąco, koniec z czekaniem całymi dniami na wyniki firmy"
        />
        <ValueCard
          title="Sharing"
          description="Dziel się wynikami ze swoim zespołem i inwestorami"
        />
      </ValueRow>
    </div>
  )
}

export default Page
