import React from 'react'

import * as styles from './valueRow.module.css'
import FullWidth from '../fullWidth'

export const ValueRow = ({ children }) => {
  return (
    <FullWidth>
      <div className={styles.row}>{children}</div>
    </FullWidth>
  )
}

export const ValueCard = ({ icon, title, description }) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>{icon}</div>
      <p>
        <b>{title}</b>
      </p>
      <div>{description}</div>
    </div>
  )
}
