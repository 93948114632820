import React from 'react'
import Layout from '../../components/layout'
import FullWidth from '../../components/fullWidth'
import IntegrationValues from '../../components/IntegrationValues'
import Testimonial from '../../components/Testimonial'
import Seo from '../../components/seo'
import { TalkWithFoundersCta } from '../../components/SignUp'
import classnames from 'classnames'
import shareImage from '../../images/integrations/ifirma/share-image.png'
import ifirmaLogo from '../../images/logos/ifirma.svg'

import * as heroStyle from '../../components/webreport/ReportHero.module.css'
import * as style from './ifirma.module.css'

const Integrations = () => (
  <Layout nakedBar trialCta>
    <Seo
      title="Integracja ifirma dla SaaS"
      description={
        'Integracja Probe + ifirma. Bezproblemowa analityka na podstawie faktur w ifirma.'
      }
      keywords={['integracja', 'ifirma', 'saas', 'mrr', 'bi', 'analityka']}
      twitterImg={shareImage}
      mainpageImg={shareImage}
    />
    <FullWidth>
      <div className={heroStyle.heroContent}>
        <div className={classnames(heroStyle.heroCopy, style.heroCopyWidth)}>
          <h1 className={heroStyle.title}>
            Analityka biznesowa dla użytkowników ifirma
          </h1>
          <p className={heroStyle.subtitle}>
            Na podstawie faktur z ifirmy poznasz jaki jest faktyczny stan
            Twojego biznesu. Dowiesz się ile wynosi Twój MRR, zobaczysz kohorty
            użytkowników i dowiesz się, jak MRR zmieniał się w czasie.
          </p>
          <p className={heroStyle.subtitle}>
            Mamy ponad 50 metryk, dzięki którym zaczniesz podejmować decyzje na
            podstawie danych.
          </p>
          <div>
            <TalkWithFoundersCta />
          </div>
        </div>
        <div className={classnames(heroStyle.heroImage, style.heroImageWidth)}>
          <div className={style.ifirmaLogoWrapper}>
            <img src={ifirmaLogo} width="200px" alt="ifirma logo" />
          </div>
        </div>
      </div>
      <Testimonial
        person="Adam Wrotkowski"
        title="Head of Finance"
        url="https://edrone.me"
      >
        "W Edrone używamy Probe na kilka sposobów. Po pierwsze: dostarcza nam
        wartość w codziennych działaniach, takich jak zrozumienie poszczególnych
        klientów. Po drugie: służy jako niezawodne źródło danych dla inwestorów.
        I po trzecie: podejmujemy decyzje strategiczne w oparciu o metryki
        dostarczone przez Probe."
      </Testimonial>
    </FullWidth>
    <IntegrationValues />
  </Layout>
)

export default Integrations
